.LoginLogo {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 90px;
}

.LoginLogo img {
  max-width: 100%;
  height: auto;
}

form button .fa-spin {
  margin-right: 10px;
}

form .form-group {
  margin: 15px auto;
}
