.rbc-calendar .rbc-current-time-indicator,
.rbc-calendar .rbc-current-time-indicator::before {
  background-color: #9A0059;
}

.LoginLogo {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 90px;
}

.LoginLogo img {
  max-width: 100%;
  height: auto;
}

form button .fa-spin {
  margin-right: 10px;
}

form .form-group {
  margin: 15px auto;
}

#sidebar {
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 50px;
  top: 0;
  bottom: 0;
  position: fixed;
  border-right: 1px solid #EAEAEA;
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  overflow-x: hidden;
  
}

#sidebar {
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 50px;
  top: 0;
  bottom: 0;
  position: fixed;
  border-right: 1px solid #EAEAEA;
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  overflow-x: hidden;
  
}

