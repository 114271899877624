#sidebar {
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 50px;
  top: 0;
  bottom: 0;
  position: fixed;
  border-right: 1px solid #EAEAEA;
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  overflow-x: hidden;
  
}
